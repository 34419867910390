













































































import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, StructureDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import {ArkServerManagerClient, ServerDto, SurvivorDto, TribeDto} from '@/logic/ArkServerManagerClient';
import moment from 'moment';

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class StructureById extends Vue {
    @Prop({default: ''}) private id!: string;
    serverId: number | null = null;
    ownerId: number | null = null;
    errorNoSuchStructure = false;
    errorRetrievingServer = false;
    errorRetrievingTribe = false;
    errorRetrievingPlayer = false;

    public serverUrl(serverId: number): string {
        return `https://ark.domination-gaming.com/server/${serverId}`;
    }

    public tribeUrl(serverId: number, tribeId: number): string {
        return `/tribe/${serverId}-${tribeId}`;
    }

    public survivorUrl(serverId: number, survivorId: number): string {
        return `https://ark.domination-gaming.com/server/${serverId}/survivor/${survivorId}`;
    }

    @AsyncComputedProp()
    public async structure(): Promise<StructureDto> {
        const structure = (await ArkStatsBackendClient.getStructures({
            filter: 'id==' + this.id,
            limit: 1
        })).data[0];
        if (structure) {
            this.serverId = structure.serverId;
            this.ownerId = structure.ownerId;
        } else {
            this.errorNoSuchStructure = true;
        }
        return structure;
    }

    @AsyncComputedProp()
    public async server(): Promise<ServerDto | null> {
        if (this.serverId) {
            try {
                return await ArkServerManagerClient.getServer(this.serverId);
            } catch (ex) {
                this.errorRetrievingServer = true;
            }
        }
        return null;
    }

    @AsyncComputedProp()
    public async tribe(): Promise<TribeDto | null> {
        if (this.serverId && this.ownerId) {
            try {
                return await ArkServerManagerClient.getTribe(this.serverId, this.ownerId);
            } catch (ex) {
                this.errorRetrievingTribe = true;
            }
        }
        return null;
    }

    @AsyncComputedProp()
    public async survivor(): Promise<SurvivorDto | null> {
        if (this.serverId && this.ownerId && this.errorRetrievingTribe) {
            try {
                return await ArkServerManagerClient.getSurvivor(this.serverId, this.ownerId);
            } catch (ex) {
                this.errorRetrievingPlayer = true;
            }
        }
        return null;
    }

    public localDate(timestamp: string | Date): string {
        const dateFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} as const;
        return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(timestamp));
    }
}
